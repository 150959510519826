.pc

    &__seqe
        position: relative
        padding: var(--space-lg) var(--space-md) 0 var(--space-md)

    &__seqe-content
        text-align: center
        position: relative
        width: 100%

        .grid
            padding-top: var(--space-md)
            
            
    &__seqe-image
        position: relative
        grid-column: 3/6
        z-index: -1

        // margin-top: calc(var(--space-xl) * -1)
    &__seqe-image-container
        position: absolute
        // top: calc(var(--space-xl) * -1)
        top: 0
        left: 0
        width: 100%   
        
        img
            width: 100%
            height: 100%
            object-fit: cover

    &__seqe-details
        text-align: left
        grid-column: 7/13

        li
            margin-bottom: var(--space-sm)

            p
                color: var(--color-dark-grey)
                transition: color 0.3s
                
                &.active-point
                    color: #fff

    &__seqe-canvas
        grid-column: 5/9
        overflow: hidden
        display: flex
        justify-content: center
        overflow: hidden
    
        