:root {   
    /*******
    Generals
    ********/ 
    
    --color-accent: #EE1C25;
    --color-accent-light: #DF9A8B;

    --color-grey: #e2e2e2;
    --color-light-grey: #ededed;
    --color-dark-grey: #666666;
    
    --color-text-light-grey: #afafaf;
    --color-text-grey: #808080;
    --color-text-black: #1A1A1A;
    --color-black: #000;
    
    --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
    --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

    --shadow: rgba(255, 255, 255, 0.075) 0px 0px 0.5px 1px inset, rgba(0, 0, 0, 0.03) 0px 0.9px 1.5px 0px, rgba(0, 0, 0, 0.08) 0px 3.1px 5.5px 0px, rgba(0, 0, 0, 0.12) 0px 14px 25px 0px;
    --shadow-light: rgba(255, 255, 255, 0.075) 0px 0px 0.5px 1px inset, rgba(0, 0, 0, 0.024) 0px 0.3px 0.4px 0px, rgba(0, 0, 0, 0.05) 0px 0.9px 1.5px 0px, rgba(0, 0, 0, 0.1) 0px 3.5px 6px 0px;
    --shadow-sm: 0 0.3px 0.4px rgba(0, 0, 0, 0.025),0 0.9px 1.5px rgba(0, 0, 0, 0.05),0 3.5px 6px rgba(0, 0, 0, 0.1);
    

    
    --component-padding: 2rem;
    --item-preview-padding: 0.2rem;
    
    --space-unit: 1rem;
    --space-2xs: calc(var(--space-unit)*0.5625);
    --space-xxs: 0.5625rem;
    --space-xs: 0.75rem;
    --space-sm: 1.125rem;
    --space-md: 2rem;
    --space-lg: calc(var(--space-unit)*3);
    --space-xl: calc(var(--space-unit)*4.875);
    --space-2xl: calc(var(--space-unit)*7.875);
    --space-3xl: calc(var(--space-unit)*12.75);
    --space-4xl: calc(var(--space-unit)*20.625);

    --radius: 0.375em;
    --radius-lg: calc(var(--radius, 0.25em)*2);
    --radius-md: var(--radius, 0.25em);


    // Max-Width
    --max-width-xxxxs: 20rem;
    --max-width-xxxs: 26rem;
    --max-width-xxs: 32rem;
    --max-width-xs: 38rem;
    --max-width-sm: 48rem;
    --max-width-md: 64rem;
    --max-width-lg: 80rem;
    --max-width-xl: 90rem;
    --max-width-xxl: 100rem;
    --max-width-xxxl: 120rem;
    --max-width-xxxxl: 150rem;


    // Modal
    --modal-transition-duration: 0.2s;

    /*******
    Checkboxes
    ********/ 
    --custom-checkbox-size: 20px;
    --custom-checkbox-radius: 0;
    --custom-checkbox-border-width: 1px;
    --custom-checkbox-marker-size: 18px;
    --page-bg: var(--color-grey);
    --page-color: var(--color-text-black);
    --header-color: var(--color-text-grey);
    --header-color-bg: var(--color-light-grey);
    

}


[data-theme=dark] {
    --header-color-bg: #000;
    --page-color: #fff;
    --page-bg: var(--color-text-black);
}