.header
    position: fixed
    left: 0
    bottom: 0
    z-index: 10
    width: 100%
    background-color: var(--header-color-bg)
    height: calc(1vw + var(--space-sm) + 1.5vw)
    transition: background-color 0.3s

    &__content
        position: relative
        display: flex
        justify-content: center
        align-items: center
        height: 100%

        .box
            height: 0.92vw
            display: inline-block
            margin-right: 1em
        
        svg
            height: 100%
            width: auto

        span
            color: var(--header-color)
            text-transform: uppercase
            font-size: 1vw
            // margin-top: -0.1em
            font-size: 20px
            font-weight: 400
            display: inline-block
            line-height: 1em

        .icon
            height: 15px
            width: auto
            margin-right: 10px

        .callToaction
            position: absolute
            top: 50%
            right: 0
            transform: translate(50%, -50%)
            -webkit-transform: translate(50%, -50%)


