.pc

    &__seqg
        position: relative
        padding: var(--space-2xl) var(--space-md) 0 var(--space-md)

    &__seqg-content
        text-align: center
        position: relative
        width: 100%

        .grid
            padding-top: var(--space-md)
            

    &__seqg-image-container
        position: relative
        grid-column: 3/7

    &__seqg-image
        position: absolute
        top: -60%
        left: 0
        width: 100%
        height: 0
        padding-bottom: 100%


    &__seqg-details
        text-align: left
        grid-column: 7/13

        li
            margin-bottom: var(--space-sm)

            p
                color: var(--color-dark-grey)
                transition: color 0.3s
                
                &.active-point
                    color: var(--color-text-black)

