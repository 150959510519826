.pc
    &__seqi
        position: relative
        display: flex
        justify-content: center

    &__seqi
        align-items: center
        min-height: auto
        height: 100%
    
    &__seqi-degree
        position: absolute
        top: var(--component-padding)
        left: var(--component-padding)

        .icon
            width: 2.6vw
            height: 2.6vw
            color: var(--color-dark-grey)
            stroke: var(--color-dark-grey)

    &__seqi-ctrl
        position: absolute
        left: 0
        bottom: calc(1vw + var(--space-sm) + 1.5vw)
        width: 100%
        padding: var(--space-sm) var(--component-padding)
        display: flex
        justify-content: space-between
    
    &__seqi-ctrl-return
        flex-basis: 20%

    &__seqi-ctrl-return-btn
        &:hover
            .icon
                transform: scale(1.1)
                
                .svg-fill-grey
                    fill: #000

                .svg-stroke-grey
                    stroke: #000

        &:focus-visible
            .icon
                transform: scale(1.1)
                
                .svg-fill-grey
                    fill: var(--color-accent)

                .svg-stroke-grey
                    stroke: var(--color-accent)

        .icon
            width: 1.5vw
            height: 1.5vw
            color: var(--color-dark-grey)
            stroke: var(--color-dark-grey)
            transform: scale(1.0)
            transition: color 0.2s, stroke 0.2s, transform 0.2s
    
    &__seqi-ctrl-texture
        text-align: center
        flex-basis: 60%
        display: flex
        align-items: center
        justify-content: center

        span
            color: var(--color-text-grey)
            font-size: 1.5vw
            text-transform: uppercase
            font-weight: 500

            &.active-texture
                color: var(--color-text-blac)
                
    &__seqi-ctrl-texture-content
        margin-right: var(--space-md)
    
    &__seqi-ctrl-texture-checkboxes
        // display: flex
        // align-items: center
            

    &__seqi-ctrl-details
        flex-basis: 20%
        display: flex
        align-items: center

        span
            color: var(--color-text-light-grey)
            font-size: 0.8vw
            letter-spacing: 0.05em
            text-transform: uppercase
            display: block
            font-weight: 500

    &__seqi-popup
        position: absolute
        top: 0
        left: 0
        width: 100%
        max-width: 18vw
        z-index: 10
        visibility: hidden
        opacity: 0
        overflow: hidden
        

        &:not(.popup--is-visible)
            pointer-events: none
        
        &.popup--is-visible 
            opacity: 1
            visibility: visible

    &__seqi-popup-content
        position: relative
        overflow: hidden
        padding: var(--space-xs)
        background: rgba(#fff, 0.6)

    &__seqi-popup-list-item
        display: flex
        gap: var(--space-sm)
        align-items: center
        margin-bottom: var(--space-xs)
    
        &:first-child
            margin-bottom: 0
            margin-bottom: calc(var(--space-unit)*0.1)        

        figure
            width: 3vw
            height: 3vw
            flex-shrink: 0
            display: flex
            align-items: center
            justify-content: center
            
            .icon
                width: 2vw
                height: 2vw
            // 41 14
            #icon-timer-display
                height: 0.8vw
                width: auto
                margin-top: -0.2em
            // 39 39
            #icon-schalter
                width: 2.5vw
                height: 2.5vw
            // 22 24
            #icon-tassenzahl
                width: auto
                height: 1.4vw
            // 23 22
            #icon-timer
                width: 1.6vw
                height: auto
            // 17 24
            #icon-wasser
                width: auto
                height: 1.6vw
            // 21 29
            #icon-kalk
                width: auto
                height: 1.6vw



    &__seqi-popup-list-item-details
        color: var(--color-dark-grey)
        




    // &__seqi-poi
    //     pointer-events: none



    // &__seqi-poi-item
    //     position: absolute
    //     pointer-events: auto

    //     &:first-child
    //         top: 56%
    //         left: 35%
            
    //     &:last-child
    //         top: 0
    //         right: 0
    
    // &__seqi-poi-btn
    //     display: flex
    //     align-items: center
    //     justify-content: center
    //     --size: 20px
    //     height: var(--size)
    //     width: var(--size)
    //     border-radius: 50%
    //     background-color: var(--color-accent)
    //     box-shadow: var(--shadow-sm)
    //     cursor: pointer
    //     transition: .2s

    //     &::after
    //         content: ''
    //         position: absolute
    //         width: 100%
    //         height: 100%
    //         top: 0
    //         left: 0
    //         border-radius: inherit
    //         pointer-events: none
    //         box-shadow: inset 0 0 1px 1px var(--color-accent)
    //         animation: poi-pulse 2s infinite

// .pc__seqi-poi-item button
//     backdrop-filter: blur(10px)
//     &::after
//         display: none

// @keyframes poi-pulse
//     0%
//         transform: scale(1)
//         opacity: 1
//     50% 
//         opacity: 1
//     100% 
//         transform: scale(1.6)
//         opacity: 0
  