.pc
    position: relative
    overflow-x: hidden


    &__seq
        position: relative
        width: 100%
        min-height: 100vh
        overflow: hidden
        
    &__seqa-canvas,
    &__seqb-canvas
        background: var(--color-text-black)

    &__seqa-canvas,
    &__seqb-canvas,
    &__seqh-canvas
        position: absolute
        top: 0
        left: 0
        z-index: -1
        opacity: 1
        transition: opacity 1s var(--ease-out)

.password
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    z-index: 100
    background-color: #1a1a1a
    color: #fff
    display: flex
    justify-content: center
    align-items: center

    &__content
        position: relative
        width: 100%
        max-width: 520px
        
        h3
            margin-bottom: var(--space-sm)

        #password-request-feedback
            color: var(--color-accent)
            font-weight: 700

    &__form
        display: flex
        justify-content: space-between
        align-items: flex-end
        
        input
            position: relative
            width: 100%
            padding: var(--space-2xs)
            font-size: 1.5vw
            line-height: 1em
            flex-basis: 75%

.grid
    display: grid
    grid-template-columns: repeat(12, 1fr)

.custom-checkbox
    
    &:first-child
        margin-right: 5px
        font-size: 1.5vw

    .custom-checkbox__input
        width: 1.5vw
        height: 1.5vw

.checkbox-grey

    .custom-checkbox__control
        &::after
            background-color: #c5c5c5
    
.checkbox-grey .custom-checkbox__input:checked ~ .custom-checkbox__control
    color: #c5c5c5
    
.checkbox-black .custom-checkbox__input:checked ~ .custom-checkbox__control
    color: var(--color-text-black)