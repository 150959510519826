.pc
    &__seqj
        position: relative
        padding: var(--space-lg) var(--space-md)

        .heading-abs
            position: absolute
            top: var(--space-lg)
            left: 50%
            -webkit-transform: translateX(-50%)
            transform: translateX(-50%)
            h3
                font-weight: 600
                text-align: center

.slideshow
    position: relative
    width: 100%
    max-width: 1800px
    margin: 0 auto
    height: 100%
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    
    &__container
        width: 18vw
        height: 18vw
        position: relative
        perspective: 850px

    &__content
        height: 100%
        width: 100%
        position: absolute
        transform-style: preserve-3d
        transition: transform 1s

    &__item-content
        display: block
        position: absolute
        width: 18vw
        text-align: center
        opacity: 0.5
        pointer-events: none
        transition: transform 1s, opacity 1s

        &.active-slide
            opacity: 1
            pointer-events: auto
        &.back-slide
            opacity: 0

    &__item-image
        padding: var(--space-xs)    
        user-select: none
        cursor: pointer
        border: 1px dashed geen

        &:hover,
        &:focus-visible
            .callToaction
                background-color: var(--color-accent)
                color: #fff
                border-color: var(--color-accent)

        .callToaction
            margin-top: var(--space-sm)
            background-color: transparent
            color: #A9A7A5
            border-width: 2px
            line-height: 1em
            border-style: solid
            border-color: #A9A7A5
            transition: box-shadow 0.2s, background-color 0.3s, color 0.3s border-color 0.3s


    &__item
        &.slideshow__item-0
            transform: rotateY(0deg) translateZ(18vw)
        &.slideshow__item-1
            transform: rotateY(90deg) translateZ(18vw) rotateY(-90deg)
        &.slideshow__item-2
            transform: rotateY(180deg) translateZ(18vw) rotateY(-180deg)
        &.slideshow__item-3
            transform: rotateY(270deg) translateZ(18vw) rotateY(-270deg)

    &__control
        position: absolute
        z-index: 4
        top: 50%
        -webkit-transform: translateY(-50%)
        transform: translateY(-50%)
        &:first-of-type
            left: var(--space-lg)
            
            .slideshow__btn .icon
                margin-right: 0.1em
                -webkit-transform: rotate(180deg)
                transform: rotate(180deg)

        &:last-of-type
            right: var(--space-lg)

            .slideshow__btn .icon
                margin-left: 0.1em

    &__btn
        width: 3.5vw
        height: 3.5vw
        border-radius: 50%
        background: #A9A7A5
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        transition: background-color 0.3s

        &:hover,
        &:focus-visible
            background-color: var(--color-accent)
            color: var(--color-accent)

        .icon
            font-size: 1.5vw
            color: #fff


.slideshow__content li
    transform-style: preserve-3d


