.pc

    &__seqd
        position: relative
        padding: var(--space-lg) var(--space-md)

    &__seqd-content
        text-align: center
        position: relative
        width: 100%

        h2
            z-index: 2

        .grid
            padding-top: var(--space-md)

    &__seqd-details
        text-align: left
        grid-column: 7/13

        li
            margin-bottom: var(--space-sm)

            p
                color: var(--color-dark-grey)
                transition: color 0.3s
                
                &.active-point
                    color: #fff

    &__seqd-canvas
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        z-index: -1