.modal
    position: fixed
    z-index: 1000
    width: 100%
    height: 100%
    left: 0
    top: 0
    background-color: rgba(#e2e2e2, 1.0)
    display: flex
    justify-content: center
    align-items: center
    padding: var(--space-sm)
    opacity: 0
    visibility: hidden

    @include breakpoint(sm)
        padding: var(--space-md)

    &:not(.modal--is-visible)
        pointer-events: none
        background-color: transparent

    &.modal--is-visible
        opacity: 1
        visibility: visible

    &__content
        position: relative
        width: 100%
        overflow: auto
        max-height: 100%        

    &__info
        display: flex
        flex-flow: column
        padding: var(--space-md) 0

    &__info-body
        padding-bottom: var(--space-md)

        h3
            font-weight: 600
            font-size: 2.9vw
            margin-bottom: var(--space-lg)

        .textref
            font-size: 1.3vw
            margin-left: 1.5em
            
            .icon
                font-size: 1.3vw
            
            span
                margin-right: var(--space-2xs)


    &__info-list
        list-style-type: disc
        font-size: 1.3vw
        padding-left: 1.5em
        margin-bottom: var(--space-lg)

        li
            font-weight: 500
            font-size: 1.3vw
            line-height: 1em
            margin-bottom: var(--space-xs)

    &__info-footer
        font-size: 1.3vw
        padding-left: 1.5em
        padding-top: var(--space-lg)
        display: flex
        justify-content: space-between
        .callToaction
            font-size: 1vw

        .icon
            font-size: 5vw
    
    &__close-btn--inner
        position: absolute
        top: 0
        left: 0
        cursor: pointer
        display: flex
        align-items: center
        transition: 0.2s
        z-index: 15
        
        &:hover,
        &:focus,
        &:focus-visible
            span
                color: var(--color-accent)

            .icon
                stroke: var(--color-accent)

        span
            font-size: 1vw
            text-transform: uppercase
            letter-spacing: 0.1em
            font-weight: 500
            color: #939393
            
        .icon
            font-size: 1vw
            margin-top: -0.1em
            margin-right: 0.2em
            stroke: #939393

.modal--animate-fade,
.modal--animate-scale,
.modal--animate-translate-up,
.modal--animate-translate-down,
.modal--animate-translate-right,
.modal--animate-translate-left,
.modal--animate-slide-up,
.modal--animate-slide-down,
.modal--animate-slide-right,
.modal--animate-slide-left
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration)
    
    &.modal--is-visible
        transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s

        .modal__content
            -webkit-transform: scale(1)
            transform: scale(1)

.modal--animate-scale .modal__content,
.modal--animate-translate-up .modal__content,
.modal--animate-translate-down .modal__content,
.modal--animate-translate-right .modal__content,
.modal--animate-translate-left .modal__content,
.modal--animate-slide-up .modal__content,
.modal--animate-slide-down .modal__content,
.modal--animate-slide-right .modal__content,
.modal--animate-slide-left .modal__content,
.modal--animate-slide-up.modal--is-visible .modal__content,
.modal--animate-slide-down.modal--is-visible .modal__content,
.modal--animate-slide-right.modal--is-visible .modal__content,
.modal--animate-slide-left.modal--is-visible .modal__content
    will-change: transform
    transition: -webkit-transform var(--modal-transition-duration) var(--ease-out)
    transition: transform var(--modal-transition-duration) var(--ease-out)
    transition: transform var(--modal-transition-duration) var(--ease-out), -webkit-transform var(--modal-transition-duration) var(--ease-out)

.modal--animate-scale .modal__content
    -webkit-transform: scale(0.95)
    transform: scale(0.95)
                
.modal--animate-translate-up .modal__content
    -webkit-transform: translateY(40px)
    transform: translateY(40px)

.modal--animate-translate-down .modal__content
    -webkit-transform: translateY(-40px)
    transform: translateY(-40px)

.modal--animate-translate-left .modal__content
    -webkit-transform: translateX(-40px)
    transform: translateX(-40px)

.modal--animate-slide-up .modal__content
    -webkit-transform: translateY(100%)
    transform: translateY(100%)

.modal--animate-slide-down .modal__content
    -webkit-transform: translateY(-100%)
    transform: translateY(-100%)

.modal--animate-slide-right .modal__content
    -webkit-transform: translateX(-100%)
    transform: translateX(-100%)

.modal--animate-slide-left .modal__content
    -webkit-transform: translateX(100%)
    transform: translateX(100%)

.modal--is-loading .modal__content
    visibility: hidden

.modal--is-loading .modal__loader
    display: flex

.modal__loader
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    justify-content: center
    align-items: center
    display: none
    pointer-events: none
    
    .icon
        font-size: 48px
        color: var(--color-green)

        &.icon--is-spinning
            animation: icon-spin 1s infinite linear