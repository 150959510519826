.pc
    &__seqk
        position: relative
        padding: var(--space-lg) var(--space-md)
        display: flex
        justify-content: center

    &__seqk-content
        position: relative
        width: 100%
        text-align: center

        .icon
            height: 2vw
            width: auto
            margin:0 auto var(--space-lg) auto


    &__seqk-grid
        position: relative
        display: flex
        justify-content: space-between
        padding-top: calc(var(--space-lg) *2)
        margin-bottom: calc(1vw + var(--space-sm) + 1.5vw)

    &__seqk-grid-col
        position: relative
        flex-basis: calc(25% - 1vw)
        background: #fff
        text-align: left
        padding: var(--space-sm)
    
    &__seqk-grid-header
        
        .icon
            height: 0.65vw
            width: auto
            margin: 0

        h4
            padding-top: calc(var(--space-unit)*0.5)
            font-size: 1.62vw

        h5
            font-size: 0.95vw
            color: var(--color-dark-grey)

        .spacer
            height: 0.95vw

    &__seqk-grid-image
        max-width: 80%
        margin: 0 auto
        

    &__seqk-grid-footer
        display: flex
        justify-content: flex-end

        .callToaction
            font-size: 0.95vw

            &:hover,
            &:focus-visible
                background-color: var(--color-light-grey)
                color: var(--color-accent)